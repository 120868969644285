<template>
  <div class="dashboard">
    <!-- Barra lateral -->
    <b-sidebar bg-variant="dark" text-variant="light" shadow>
      <h3 class="text-center py-3">Mi Dashboard</h3>
      <b-list-group flush>
        <b-list-group-item href="#" active><i class="fas fa-tachometer-alt"></i> Dashboard</b-list-group-item>
        <b-list-group-item href="#"><i class="fas fa-dollar-sign"></i> Ventas</b-list-group-item>
        <b-list-group-item href="#"><i class="fas fa-users"></i> Clientes</b-list-group-item>
        <b-list-group-item href="#"><i class="fas fa-chart-line"></i> Reportes</b-list-group-item>
      </b-list-group>
    </b-sidebar>

    <!-- Contenido principal -->
    <b-container fluid class="main-content">
      <!-- Bienvenida -->
      <b-card class="text-center mb-4 welcome-card">
        <h4>Bienvenido/a al Dashboard</h4>
        <p>Hoy es {{ currentDate }}</p>
      </b-card>

      <!-- Estadísticas Rápidas -->
      <b-row class="mb-4 stats">
        <b-col md="4">
          <b-card class="stat-card text-center">
            <i class="fas fa-cash-register fa-3x text-primary mb-3"></i>
            <h5>Ventas Totales</h5>
            <p class="stat-value">$45,000.00</p>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="stat-card text-center">
            <i class="fas fa-users fa-3x text-success mb-3"></i>
            <h5>Clientes</h5>
            <p class="stat-value">1,200</p>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="stat-card text-center">
            <i class="fas fa-chart-line fa-3x text-warning mb-3"></i>
            <h5>Ingresos Mensuales</h5>
            <p class="stat-value">$15,000.00</p>
          </b-card>
        </b-col>
      </b-row>

      <!-- Ventas Recientes -->
      <b-row>
        <b-col md="4" v-for="sale in recentSales" :key="sale.id" class="mb-4">
          <b-card class="sale-card text-center">
            <h6><i class="fas fa-receipt text-info"></i> Venta #{{ sale.id }}</h6>
            <p><strong>{{ sale.cliente }}</strong></p>
            <p>{{ sale.fecha }} - {{ sale.totalVenta }}</p>
            <b-badge :variant="sale.estado === 'Pagada' ? 'success' : 'warning'">{{ sale.estado }}</b-badge>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentDate: new Date().toLocaleDateString(),
      recentSales: [
        { id: 1149, cliente: "Cliente Genérico", fecha: "06-04-2023", totalVenta: "$0.00", estado: "Pagada" },
        { id: 1148, cliente: "Cliente Genérico", fecha: "06-04-2023", totalVenta: "$0.00", estado: "Pagada" },
        { id: 1141, cliente: "Cliente Genérico", fecha: "06-04-2023", totalVenta: "$0.00", estado: "Pendiente" },
      ]
    };
  }
};
</script>

<style scoped>
.dashboard {
  display: flex;
}

.main-content {
  margin-left: 250px;
  padding: 1.5rem;
}

.welcome-card {
  background-color: #007bff;
  color: white;
}

.stats .stat-card {
  padding: 1.5rem;
  border: none;
}

.stat-card h5 {
  font-weight: bold;
  margin-top: 1rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.sale-card {
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.sale-card h6 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
</style>