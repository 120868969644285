<template>
  <div class="row clearfix">
    <!-- <card-actions></card-actions> -->
    <!-- <div class="col-md-12">
      <div class="card p-4 mb-4">
        <div class="row py-3 justify-content-between">
          <div class="card-header py-3 d-flex flex-wrap justify-content-between align-items-center  ">
            <div>
              <input @click="showModal('', 1)" type="button" class="btn btn-primary" value="Nuevo" />
            </div>
            <card-actions></card-actions>
          </div>

          <div class="col-md-5">
            <b-form-group label="Por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(fecha_alta)="data">
            <span>{{ data.item.fecha_alta != null ? $moment(data.item.fecha_alta).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(action)="data">
            <span
              ><button @click="showModal(data.item, 2)" class="btn btn-primary ml-1"><i class="fa fa-pencil"></i></button>
            </span>
            <span
              ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1"><i class="fa fa-trash-o"></i></button>
            </span>
 
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            hide-goto-end-buttons
            hide-ellipsis
            prev-text="Prev"
            next-text="Next"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div> -->
    <div class="   ">
      <div class="container mt-4">
       
      </div>
          <form @submit.prevent="saveData()">
            <!-- {{ mapaUsuario }} -->
            <div class="row">
              <!-- <div class="col-md-6 mb-2">
                <label for="nombre" class="">Nombreusuario</label><input required v-model="row.nombreusuario" class="form-control" type="text" name="nombre" id="nombre" />
              </div> -->
  
              <!-- <div class="col-md-6 mb-2">
                <label for="clave" class="">Clave</label>
                <input class="form-control" v-model="row.clave" type="text" name="clave" id="clave" />
              </div>
              <div class="col-md-6 mb-2">
                <label for="nombre" class="">Nombres</label><input required v-model="row.nombres" class="form-control" type="text" name="nombre" id="nombre" />
              </div>
              <div class="col-md-6 mb-2">
                <label for="nombre" class="">Apellidos</label><input required v-model="row.apellidos" class="form-control" type="text" name="nombre" id="nombre" />
              </div>
  
              <div class="col-md-6 mb-2">
                <label for="email" class="">Mapa</label>
                <input class="form-control" v-model="row.mapa" type="text" name="mapa" id="mapa" />
              </div>
              <div class="col-md-6 mb-2">
                <label for="estado" class="">Estado</label>
                <b-form-select required class="form-control select  " v-model="row.estado" :options="listaEstado" value-field="estado" text-field="estado"> </b-form-select>
              </div>
              <div class="col-md-6 mb-2">
                <label for="estado" class="">Rol</label>
                <b-form-select @change="actualizaRol($event)" required class="form-control select  " v-model="row.rol" :options="roles" value-field="rol" text-field="rol">
                </b-form-select>
              </div>
              <div class="col-md-6 mb-2">
                <label for="observaciones" class="">Observaciones</label
                ><input class="form-control" v-model="row.observaciones" type="text" name="observaciones" id="observaciones" />
              </div> -->
              <div class="row" >
                <template>
                  <div class="col-12 mt-3 ">
                    39
                    <div class="form-check">
                      <b-form-checkbox
                      switch
                        class="secondary"
                        size="small"
                        @change="updateMap(39, $event)"
                        :id="'checkbox-2-39'"
                        :name="'checkbox-2-39'"
                        value="1"
                        unchecked-value="0"
                        v-model="mapaUsuario[39]"
                      >
                        <span for="observaciones" class="h6">panel izquierdo</span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </template>
                <template   v-for="(it, item1) of menuItem" >
                  <div  v-if="it.userView== true" class="col-4 pb-2 px-1 mx-0 " :key="it.id" >
                   <div class="bg-card px-0 mx-0 pb-3 rounded  text-left" >
                     <div class="col-12 mx-0  px-0" :key="it.level">
                       <!-- {{ it.level }} -->
                       <div class="form-check  text-left py-3   rounded mx-0" style=" ">
                         <b-form-checkbox
                         class=""
                         size="lg"
                         @change="updateMap(it.level, $event)"
                         :id="'checkbox-1-' + it.level + item1"
                         v-model="mapaUsuario[it.level]"
                         :name="'checkbox-1-' + it.level + item1"
                         value="1"
                         switch
                         unchecked-value="0"
                         >
                         <span for="observaciones" class="h5 text-primary"><b>{{ it.name }}</b> </span>
                         </b-form-checkbox>
                       </div>
   
                       <template v-if="it.children.length > 0">
                         <template v-for="(itm, item2) of it.children">
                           <div class="col-12 py-2 px-3  " style="border-top: solid 1px var(--border-color)" :key="itm.level">
                             <!-- {{ itm.level }} -->
                             <!-- {{ mapaUsuario[itm.level] }} -->
                             <div class="form-check">
                               <b-form-checkbox
                               
                                 switches
                                 switch
                                 class="secondary"
                              
                                 @change="updateMap(itm.level, $event)"
                                 :id="'checkbox-2-' + itm.level + item1 + item2"
                                 :name="'checkbox-2-' + itm.level + item1 + item2"
                                 value="1"
                                 unchecked-value="0"
                                 v-model="mapaUsuario[itm.level]"
                               >
                                 <span for="observaciones" class="h6">{{ itm.name }}</span>
                               </b-form-checkbox>
                             </div>
                           </div>
                         </template>
                       </template>
                     </div>
                   </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="text-right mt-3">
              <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
              <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
            </div>
          </form>
        </div>
     
      

    <!-- <b-modal size="xl" modal-class="modal-fullscreen" id="exampleModalCenter" tabindex="-1" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          
          <div class="row">
            <div class="col-md-6 mb-2">
              <label for="nombre" class="">Nombreusuario</label><input required v-model="row.nombreusuario" class="form-control" type="text" name="nombre" id="nombre" />
            </div>

            <div class="col-md-6 mb-2">
              <label for="clave" class="">Clave</label>
              <input class="form-control" v-model="row.clave" type="text" name="clave" id="clave" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="nombre" class="">Nombres</label><input required v-model="row.nombres" class="form-control" type="text" name="nombre" id="nombre" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="nombre" class="">Apellidos</label><input required v-model="row.apellidos" class="form-control" type="text" name="nombre" id="nombre" />
            </div>

            <div class="col-md-6 mb-2">
              <label for="email" class="">Mapa</label>
              <input class="form-control" v-model="row.mapa" type="text" name="mapa" id="mapa" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="estado" class="">Estado</label>
              <b-form-select required class="form-control select  " v-model="row.estado" :options="listaEstado" value-field="estado" text-field="estado"> </b-form-select>
            </div>
            <div class="col-md-6 mb-2">
              <label for="estado" class="">Rol</label>
              <b-form-select @change="actualizaRol($event)" required class="form-control select  " v-model="row.rol" :options="roles" value-field="rol" text-field="rol">
              </b-form-select>
            </div>
            <div class="col-md-6 mb-2">
              <label for="observaciones" class="">Observaciones</label
              ><input class="form-control" v-model="row.observaciones" type="text" name="observaciones" id="observaciones" />
            </div>
            <div class="row" style="height: 300px !important ; overflow-y: auto !important">
              <template>
                <div class="col-12 mt-3 ml-4">
                  39
                  <div class="form-check">
                    <b-form-checkbox
                      switches
                      class="secondary"
                      size="small"
                      @change="updateMap(39, $event)"
                      :id="'checkbox-2-39'"
                      :name="'checkbox-2-39'"
                      value="1"
                      unchecked-value="0"
                      v-model="mapaUsuario[39]"
                    >
                      <span for="observaciones" class="h6">panel izquierdo</span>
                    </b-form-checkbox>
                  </div>
                </div>
              </template>
              <div class="col-6 p-4" v-for="(it, item1) of menuItem" :key="it.id">
                <div :key="it.id">
                  <div class="col-12" :key="it.level">
                   
                    <div class="form-check">
                      <b-form-checkbox
                        size="md"
                        @change="updateMap(it.level, $event)"
                        :id="'checkbox-1-' + it.level + item1"
                        v-model="mapaUsuario[it.level]"
                        :name="'checkbox-1-' + it.level + item1"
                        value="1"
                        unchecked-value="0"
                      >
                        <span for="observaciones" class="h6">{{ it.name }}</span>
                      </b-form-checkbox>
                    </div>

                    <template v-if="it.children.length > 0">
                      <template v-for="(itm, item2) of it.children">
                        <div class="col-12" :key="itm.level">
                        
                          <div class="form-check">
                            <b-form-checkbox
                              switches
                              class="secondary"
                              size="small"
                              @change="updateMap(itm.level, $event)"
                              :id="'checkbox-2-' + itm.level + item1 + item2"
                              :name="'checkbox-2-' + itm.level + item1 + item2"
                              value="1"
                              unchecked-value="0"
                              v-model="mapaUsuario[itm.level]"
                            >
                              <span for="observaciones" class="h6">{{ itm.name }}</span>
                            </b-form-checkbox>
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right mt-3">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal> -->
  </div>
</template>
<script>
// import CardActions from "@/components/core/CardActions.vue";
import menu from "@/components/admin/data/menu.json";

export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
    // "card-actions": CardActions,
  },
  data() {
    return {
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        { key: "nombreusuario", label: "Nombre", sortable: true, class: "text-center" },
        // { key: "fecha_alta", label: "fecha alta", sortable: true, class: "text-center" },
        { key: "estado", label: "estado", sortable: true, class: "text-center" },
        { key: "rol", label: "rol", sortable: true, class: "text-center" },
        // { key: "mapa", label: "Mapa", sortable: true, class: "text-center" },
        { key: "action", label: "", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      ///////
      table: "usuarios",
      items: [],
      row: { mapa: "" },
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo Usuario",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      roles: [
        { rol: "SuperAdmin", mapa: "1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1" },
        { rol: "Administrador", mapa: "1,1,0,0,1,1,1,1,1,0,1,1,1,1,1,1,1,1,1,0,0,0,0,1,1,1,1,1,0,1,1,1,1,1,1,1,1" },
        { rol: "Vendedor", mapa: "0,0,0,0,1,0,0,1,1,0,0,0,0,0,0,0,1,0,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0" },
        { rol: "Usuario estándar", mapa: "1,1,0,0,1,1,1,1,0,0,1,1,1,1,1,1,1,1,1,0,0,0,0,1,1,1,1,0,0,1,1,1,1,1,1,1,0" },
        // { rol: "Responsable Tienda" }
      ],
      maparow: {
        v0: 0,
        v1: 0,
        v2: 0,
        v3: 0,
        v4: 0,
        v5: 0,
        v6: 0,
        v7: 0,
        v8: 0,
      },
      menuItem: [],
      mapaUsuario: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");

    await this.getData();
    this.totalRows = this.items.length;
    this.menuItem = menu.menu;
  },
  methods: {
    actualizaRol(e) {
      let filtromapa = this.roles;
      let tipoFiltrado = filtromapa.filter((x) => x.rol == e)[0];
      console.log(tipoFiltrado);
      this.row.mapa = tipoFiltrado.mapa;
      this.mapaUsuario = this.row.mapa.split(",");
    },
    updateMap(itemf, e) {
      //let index = item;
      console.log(itemf);
      //let s = this.row.mapa;
      // alert(itemf);
      itemf = itemf - 1;
      // if (itemf == 1) {
      //   console.log("elif 0");
      //   this.mapaUsuario[0] = e;
      //   console.log("vvv", this.mapaUsuario[0]);
      //   // this.row.mapa = this.mapaUsuario.toString();

      //   // this.row.mapa = this.mapaUsuario.toString();
      //   //let tt = s.substring(0, 0) + e + s.substring(0 + 1);
      //   this.row.mapa = this.mapaUsuario.toString();
      //   //   // this.mapaUsuario.replaceAt(1, "u");
      // } else {
      console.log(itemf);
      console.log(e);
      console.log(this.mapaUsuario);
      console.log("A", this.mapaUsuario[itemf]);
      //this.mapaUsuario[itemf] = e;
      console.log("D", this.mapaUsuario[itemf]);
      //alert(this.mapaUsuario);
      this.row.mapa = this.mapaUsuario.toString();
      //}
    },
    showModal(item, id) {
      console.log("----->", item);
      if (id == 1) {
        this.modalTitle = "Nuevo Cliente";
        this.row = { mapa: "1,1,0,0,1,1,1,1,0,0,1,1,1,1,1,1,1,1,1,0,0,0,0,1,1,1,1,0,0,1,1,1,1,1,1,1,0" };
        this.mapaUsuario = this.row.mapa.split(",");
        this.$bvModal.show("exampleModalCenter");
      } else {
        this.row = item;
        this.mapaUsuario = this.row.mapa.split(",");
        this.modalTitle = "Editar Cliente";
        this.$bvModal.show("exampleModalCenter");
        console.log(this.row);
      }
      //this.maparow = 1 //JSON.parse(JSON.stringify(this.row.mapa))
      this.maparow.v0 = this.row.mapa.split(",")[0];
      this.maparow.v1 = this.row.mapa.split(",")[1];
      this.maparow.v2 = this.row.mapa.split(",")[2];
      this.maparow.v3 = this.row.mapa.split(",")[3];
      this.maparow.v4 = this.row.mapa.split(",")[4];
      this.maparow.v5 = this.row.mapa.split(",")[5];
      this.maparow.v6 = this.row.mapa.split(",")[6];
      this.maparow.v7 = this.row.mapa.split(",")[7];
      this.maparow.v8 = this.row.mapa.split(",")[8];
      console.log("--a", this.maparow);
    },
    async getData() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: this.table + "/getAll/" });
        this.$isLoading(false);
        if (res.length > 0) {
          this.items = res;
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        this.$isLoading(true);
        console.log("save");
        let data = Object.assign({}, this.row);
        //data.empresa_id = this.company_id
        let res;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", { path: this.table + "/modify/" + this.row.id, data: data });
        } else {
          res = await this.$store.dispatch("post", { path: this.table + "/new", data: data });
        }
        this.$isLoading(false);
        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.getData();
          this.row = {};
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        this.$isLoading(true);
        try {
          let res = await this.$store.dispatch("delete", { path: this.table + "/delete/" + item.id });
          this.$isLoading(false);
          if (res.error) {
            this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          } else {
            this.getData();
            this.$swal({ title: "Aviso", text: "Eliminado correctamente", icon: "success" });
          }
        } catch (error) {
          this.$isLoading(false);
          this.items = [];
          console.error("error--->", error);
        }
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}
</style>
