<template>
    <div class="container">
      <div class=" py-5">
        <h2 class="text-center"><b> ¿Cómo planeas usar ToolB? </b></h2>
        
        <!-- <div class=" d-flex justify-content-center " >
            <div class="bg-primary img-bg tasks card-header">
                <h3><b class=""> Grabación de pantalla: </b></h3>

            </div>
            <div class="bg-primary img-bg tasks card-header">
                <h3><b class=""> Grabación de pantalla: </b></h3>

            </div>
            <div class="bg-primary img-bg tasks card-header">
                <h3><b class=""> Grabación de pantalla: </b></h3>

            </div>
        </div> -->
        <div class="row pt-5">
          <div class="col-4 px-3  " style="height: 12rem;"  >
            <div class="img-bg text-light usestoolb rounded zoom pointer active-card">
              <h3 class="px-3"><b> Trabajo</b> </h3>
            </div>
          </div>
          <div class="col-4 px-3  " style="height: 12rem;"  >
            <div class="img-bg text-light usestoolb2 rounded zoom pointer active-card">
              <h3 class="px-3"><b> Personal</b></h3>
            </div>
          </div>
          <div class="col-4 px-3  " style="height: 12rem;"  >
            <div class="img-bg text-light usestoolb3 rounded zoom pointer active-card">
              <h3 class="px-3"><b> Estudios</b></h3>
            </div>
          </div>
        </div>
        


        <!-- <div class="row pt-5">
          <div class="col-4 px-3  " style="height: 12rem;"  >
            <div class="img-bg-none  text-primary usestoolb rounded zoom pointer active-card">
              <h3 class="px-3"><b> Trabajo</b> </h3>
            </div>
          </div>
          <div class="col-4 px-3  " style="height: 12rem;"  >
            <div class="img-bg-none  text-primary usestoolb2 rounded zoom pointer active-card">
              <h3 class="px-3"><b> Personal</b></h3>
            </div>
          </div>
          <div class="col-4 px-3  " style="height: 12rem;"  >
            <div class="img-bg-none  text-primary usestoolb3 rounded zoom pointer active-card">
              <h3 class="px-3"><b> Estudios</b></h3>
            </div>
          </div>
        </div> -->
        <!-- <b-card-group class="">
            
          <b-card style="border-radius:10px ;" class="mx-3 m-0 p-0 img-card-media  "  img-alt="Image" >
           <div class=" p-0">
              <div class="text-center img-bg text-light -media-v media">
                <div class="px-3 pb-2" style="margin-top: 110px; z-index: 999px; display: flex;">
                    <h3><b class=""> Grabación de pantalla: </b></h3>
                  
                </div>
              </div>
           </div>
            
          
            
          </b-card>
  
          <b-card style="border-radius:10px ;" class="mx-3 m-0 p-0 img-card-media  "  img-alt="Image" >
           <div class=" p-0">
              <div class="text-center img-bg text-light -media-v audio">
                <div class="px-3 pb-2" style="margin-top: 110px; z-index: 999px; display: flex;">
                    <h3><b class=""> Grabación de pantalla: </b></h3>                                   
                </div>

              </div>
              <div class="px-3">
                <button class="btn btn-primary btn-block">
                    
                </button>
              </div>
           </div>
            
          
            
          </b-card>
          
          <b-card style="border-radius:10px ;" class="mx-3 m-0 p-0 img-card-media  "  img-alt="Image" >
           <div class=" p-0">
              <div class="text-center img-bg text-light -media-v audio">
                <div class="px-3 pb-2" style="margin-top: 110px; z-index: 999px; display: flex;">
                    <h3><b class=""> Grabación de pantalla: </b></h3>
                 
                 
                </div>
              </div>
           </div>
            
           
            
          </b-card>
        </b-card-group> -->
        
      </div>
      
      <!-- <button class="mt-5" @click="startRecording" :disabled="recording">Comenzar grabación</button><br />
        <button @click="stopRecording" :disabled="!recording">Detener grabación</button> -->
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      activeVid: false,
      activeAud: false,
        recording: false,
        mediaRecorder: null,
        chunks: [],
        userId: null,
        company_id: null,
        fileName: "",
        videoList: [],
      };
    },
    async mounted() {
      this.userId = localStorage.getItem("user_id");
      this.company_id = localStorage.getItem("company_id");
      this.getVideos();
    },
    methods: {
      async getVideos() {
        try {
          let res = await this.$store.dispatch("get", {
            path: "meca_media/getAllByCompany/" + this.company_id,
          });
          this.$isLoading(false);
          if (res.length > 0) {
            this.videoList = res;
          } else {
            this.videoList = [];
          }
        } catch (error) {
          this.videoList = [];
          this.$isLoading(false);
        }
      },
      async newVideo() {
        let data = {
          usuario_id: this.userId,
          empresa_id: this.company_id,
          url: this.$store.state.urlMedia + "videos/" + this.userId + "/" + this.fileName,
          comentarios: "",
          fecha: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
          nombreArchivo: this.fileName,
        };
        await this.$store.dispatch("post", {
          path: "meca_media/new",
          data: data,
        });
        this.$isLoading(false);
      },
      startRecording() {
        navigator.mediaDevices
          .getDisplayMedia({ video: true })
          .then((videoStream) => {
            navigator.mediaDevices
              .getUserMedia({ audio: true })
              .then((audioStream) => {
                const audioTracks = audioStream.getAudioTracks();
                videoStream.addTrack(audioTracks[0]);
  
                this.mediaRecorder = new MediaRecorder(videoStream);
                this.mediaRecorder.ondataavailable = (event) => {
                  if (event.data.size > 0) {
                    this.chunks.push(event.data);
                  }
                };
                this.mediaRecorder.onstop = () => {
                  // const blob = new Blob(this.chunks, { type: "video/webm" });
                  // this.uploadVideo(blob);
  
                  // const blob = new Blob(this.chunks, { type: "video/webm" });
                  const blob = new Blob(this.chunks, { type: "video/mp4" });
                  const url = URL.createObjectURL(blob);
  
                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  a.style = "display: none";
                  a.href = url;
                  a.download = "video.mp4";
                  a.click();
                  window.URL.revokeObjectURL(url);
                };
                this.mediaRecorder.start();
                this.recording = true;
              })
              .catch((error) => {
                console.error("Error al acceder al dispositivo de audio:", error);
              });
          })
          .catch((error) => {
            console.error("Error al acceder al dispositivo de video:", error);
          });
      },
      stopRecording() {
        if (this.mediaRecorder && this.recording) {
          this.mediaRecorder.stop();
          this.recording = false;
        }
      },
      uploadVideo(blob) {
        console.log(blob);
        const formData = new FormData();
        this.fileName = Math.random() + "_screen_record.webm";
        formData.append("video", blob, this.fileName);
  
        fetch(this.$store.state.url + "uploadVideo/" + this.userId, {
          method: "POST",
          body: formData,
        })
          .then(async (response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            console.log("Archivo enviado exitosamente.");
            //comentado temporalmente
            // await this.newVideo();
          })
          .catch((error) => {
            console.error("Error al enviar el archivo:", error);
          });
      },
    },
  };
  </script>
  <style scoped lang="scss" >
  
  .active-card{
    
  }
  .card-body{
    padding: 0%;
  }
  
  .bg-generico{
    background-image: url(/images/fondo-generico-02.svg);
    // background-image: url("/w3images/photographer.jpg");
    // background-color: #cccccc;
    // height: 80% !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    justify-content: center;
  
    
  }
  .bg-generico img{
    width: 200px !important;
    text-align: center !important;
    padding: 20px;
    margin: auto;
  
  }
  .card-img-top{
    height: 200px !important;
    object-fit: cover;
    border-top-left-radius:10px !important; 
    border-top-right-radius:10px !important; 
  }
  .img-flotante{
    // font-size: 70px;
    width: 50%;
    position:absolute;
    margin-top: -180px;
    z-index: 0; 
    text-align: center;
    // margin-left: 50px;
    transform:rotate(-10deg);
    color:var(--primary-dark-color);
    align-items: center !important;
    // display: flex;
    // justify-content: center;
  }

  .bg-icon-video{
    background-color: var(--primary-opacity-color-rgba);
    
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    padding: 20px;
    z-index: 0;
    border: solid 1px var(--primary-color);
    box-shadow: -1px -1px 41px 14px var(--primary-opacity-color-rgba);
    -webkit-box-shadow: -1px -1px 41px 14px var(--primary-opacity-color-rgba);
    -moz-box-shadow: -1px -1px 41px 14px var(--primary-opacity-color-rgba);}
  
  
  .loader{
    -webkit-animation: spin 0.7s linear infinite; /* Safari */
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  </style>