<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card px-3 mb-4">
        <div class="row justify-content-between">
          <div class="img-bg accounts card-header d-flex flex-wrap col-md-12 justify-content-between align-items-center">
            <!-- <div>
                        <input @click="showModal('', 1)" type="button" class="btn btn-danger" value="Asiento contable">
                    </div> -->
            <div class="">
              <p class="h3 pb-2 text-light">Mis cuentas</p>
              <button @click="showModal('', 1)" class="btn btn-primary mx-2"><i class="fa fa-plus mr-2"></i>Agregar cuenta{{ cuentainiText }}</button>

              <!-- <button  @click="show=true" class="btn btn-success mx-2"><i class="fa fa-plus mr-2"></i>Asientos contab les</button> -->

              <button @click="actualizaSaldos" class="btn btn-light mx-2">Actualizar saldos</button>
            </div>
            <card-actions></card-actions>
          </div>

          <div class="col-md-5">
            <b-form-group label="por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
          class="mt-3"
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(cuenta)="data">
            <label @click="selAccount(data.item.cuenta)">{{ data.item.cuenta }} </label>
          </template>
          <template #cell(titulo)="data">
            <label v-if="data.item.favorito">
                  <span class="py-1 px-2 text-light bg-warning" style="border-radius: 30px;  "
                    ><i class="fa-solid fa-star mr-1"></i> {{ data.item.titulo.length > 20 ? data.item.titulo.substring(0, 20) + "..." : data.item.titulo }}</span
                  ></label>
              <label v-else for="">{{ data.item.titulo }}</label>
            
            <!-- <label>
              <i v-if="data.item.favorito" class="fa fa-star yellow mr-1"></i>
              {{ data.item.titulo }}
            </label> -->
          </template>
          <!-- <span v-if="data.item.saldo < 0" style="color: red" -->
          <!-- <template #cell(saldo)="data">
            <span v-if="data.item.saldo < 0" class="red"
              ><b>{{ config.moneda + data.item.saldo }}</b></span
            >
            <span v-if="data.item.saldo > 0" style="color: blue"
              ><b>{{ config.moneda + data.item.saldo }}</b></span
            >
            <span v-if="data.item.saldo == 0">{{ config.moneda + data.item.saldo }}</span>
          </template> -->
          <template #cell(saldo)="data">
            <label
              v-if="data.item.saldo < 0"
              class="mb-0 py-1"
              style="border-radius: 8px; background-color: rgba(226, 80, 104, 0.205); min-width: 100px !important; color: rgb(252, 71, 101)"
              ><b>{{ config.moneda + data.item.saldo }} <i class="fas fa-arrow-down"></i></b
            ></label>
            <label
              v-if="data.item.saldo > 0"
              class="mb-0 py-1"
              style="border-radius: 8px; background-color: rgba(126, 226, 80, 0.205); color: rgb(17, 190, 89); min-width: 100px !important"
              ><b>{{ config.moneda + data.item.saldo }} <i class="fas fa-arrow-up"></i></b
            ></label>
            <label
              class="mb-0 py-1"
              style="border-radius: 8px; background-color: rgba(148, 148, 148, 0.205); color: rgb(184, 184, 184); min-width: 100px !important"
              v-if="data.item.saldo == 0"
              >{{ config.moneda + data.item.saldo }}</label
            >
          </template>
          <template #cell(ventas)="data">
            <label
              v-if="data.item.ventas < 0"
              class="mb-0 py-1"
              style="border-radius: 8px; background-color: rgba(226, 80, 104, 0.205); min-width: 100px !important; color: rgb(252, 71, 101)"
              ><b>{{ config.moneda + (data.item.ventas != null ? data.item.ventas : "0") }} <i class="fas fa-arrow-down"></i></b
            ></label>
            <label
              v-if="data.item.ventas > 0"
              class="mb-0 py-1"
              style="border-radius: 8px; background-color: rgba(126, 226, 80, 0.205); color: rgb(17, 190, 89); min-width: 100px !important"
              ><b>{{ config.moneda + (data.item.ventas != null ? data.item.ventas : "0") }} <i class="fas fa-arrow-up"></i></b
            ></label>
            <label
              class="mb-0 py-1"
              style="border-radius: 8px; background-color: rgba(148, 148, 148, 0.205); color: rgb(184, 184, 184); min-width: 100px !important"
              v-if="data.item.ventas == null || data.item.ventas == 0"
              >{{ config.moneda + (data.item.ventas != null ? data.item.ventas : "0") }}
            </label>
          </template>
          <template #cell(gastos)="data">
            <label
              class="mb-0 py-1"
              style="border-radius: 8px; background-color: rgba(148, 148, 148, 0.205); color: rgb(184, 184, 184); min-width: 100px !important"
              v-if="data.item.gastos == null || data.item.gastos == 0"
              >{{ config.moneda + (data.item.gastos != null ? data.item.gastos : "0") }}</label
            >
            <label class="mb-0 py-1" style="border-radius: 8px; background-color: rgba(226, 80, 104, 0.205); min-width: 100px !important; color: rgb(252, 71, 101)" v-else
              ><b class="">{{ config.moneda + (data.item.gastos != null ? data.item.gastos : "0") }} <i class="fas fa-arrow-down"></i></b
            ></label>
          </template>
          <template #cell(fecha_alta)="data">
            <span>{{ data.item.fecha_alta != null ? $moment(data.item.fecha_alta).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(action)="data">
            <span
              ><button data-toggle="tooltip" data-placement="top" title="Editar cuenta" @click="showModal(data.item, 2)" class="btn btn-primary ml-1">
                <i class="fa fa-pencil"></i>
              </button>
            </span>
            <span
              ><button data-toggle="tooltip" data-placement="top" title="Ver estado de cuenta" @click="showModal2(data.item)" class="btn btn-success ml-1">
                <i class="fa fa-eye"></i>
              </button>
            </span>
            <span
              ><button data-toggle="tooltip" data-placement="top" title="Eliminar cuenta" @click="deleteItem(data.item)" class="btn btn-danger ml-1">
                <i class="fa fa-trash-o"></i>
              </button>
            </span>

            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
            <!-- <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

            <!-- <table-action></table-action> -->
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            hide-goto-end-buttons
            hide-ellipsis
            prev-text="Prev"
            next-text="Next"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal id="modalAccount" tabindex="-1" size="lg" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-1 padding-none">
              <b-button @click="addItem()" class="px-4 py-2" variant="primary"><i class="fa fa-plus"></i></b-button>
            </div>
            <div class="col-md-6 mb-2">
              <b-form-select class="form-select" :value="'estado'" text-value="estado" text-field="estado" v-model="row.estado" :options="options"> </b-form-select>
            </div>
            <div class="col-md-5 mb-2">
              <input required v-model="row.apellido" class="form-control" type="date" name="apellido" id="apellido" />
            </div>
            <table class="table mt-1">
              <thead>
                <tr class="text-center">
                  <th scope="col">Cuenta</th>
                  <th scope="col">Titulo</th>
                  <th scope="col">Ingreso {{ config.moneda }}</th>
                  <th scope="col">Egreso {{ config.moneda }}</th>
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody class="">
                <tr class="" v-for="row of rows" :key="row.id">
                  <td class="">
                    <b-form-select class="form-select" :value="'estado'" text-value="estado" text-field="estado" v-model="row.estado" :options="options"></b-form-select>
                  </td>
                  <td class="px-none"><input required v-model="row.apellido" class="form-control" type="text" name="apellido" id="apellido" /></td>
                  <td class="px-none"><input placeholder="0" required v-model="row.apellido" class="form-control" type="text" name="apellido" id="apellido" /></td>
                  <td class="px-none"><input placeholder="0" required v-model="row.apellido" class="form-control" type="text" name="apellido" id="apellido" /></td>
                  <td class="px-none">
                    <b-button class="px-4 py-2" variant="danger"><i class="fa fa-trash-o"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <div class="col-md-6">
                            <label for="contacto" class="">Nombre contacto</label><input class="form-control" v-model="row.nombre_contacto" type="text" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6">
                            <label for="contacto" class="">Email</label><input class="form-control" v-model="row.email" type="email" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6 mb-2">
                            <label for="estado" class="">Estado</label>
                            <b-form-select required class="form-control select  "  v-model="row.estado" :options="listaEstado" value-field="estado" text-field="estado">
                            </b-form-select>
                        </div>
                        <div class="col-md-12 mb-2">
                            <label for="observaciones" class="">Observaciones</label><input class="form-control" v-model="row.observaciones" type="text" name="observaciones" id="observaciones">
                        </div> -->
          </div>
          <div class="text-right">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>

    <!-- modal de form cuenta - ok -->
    <b-modal id="exampleModalCenter" tabindex="-1" size="lg" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-6 mb-2">
              <label for="">Cuenta</label>
              <input required v-model="row.cuenta" class="form-control" type="text" name="apellido" id="apellido" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="">Titulo</label>
              <input required v-model="row.titulo" class="form-control" type="text" name="apellido" id="apellido" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="">Descripción</label>
              <input required v-model="row.descripcion" class="form-control" type="text" name="apellido" id="apellido" />
            </div>

            <div class="col-md-6 mt-4">
              <div class="form-check">
                <b-form-checkbox switches class="secondary mt-2" size="small" id="checkbox-1" name="checkbox-1" value="1" unchecked-value="0" v-model="row.favorito">
                  <span for="observaciones" class="h6">Favorito</span>
                </b-form-checkbox>
              </div>
            </div>
            <!-- <div class="col-md-6">
                            <label for="contacto" class="">Nombre contacto</label><input class="form-control" v-model="row.nombre_contacto" type="text" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6">
                            <label for="contacto" class="">Email</label><input class="form-control" v-model="row.email" type="email" name="contacto" id="contacto">
                        </div>
                        <div class="col-md-6 mb-2">
                            <label for="estado" class="">Estado</label>
                            <b-form-select required class="form-control select  "  v-model="row.estado" :options="listaEstado" value-field="estado" text-field="estado">
                            </b-form-select>
                        </div>
                        <div class="col-md-12 mb-2">
                            <label for="observaciones" class="">Observaciones</label><input class="form-control" v-model="row.observaciones" type="text" name="observaciones" id="observaciones">
                        </div> -->
          </div>
          <div class="text-right">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>

    <!-- estado de cuenta, lista de asientos x cuenta - ok -->
    <b-modal v-model="show" id="exampleModalCenter2" tabindex="-1" size="xl" centered title="Ingresa asiento" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <ledger :account="account"></ledger>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { EventBus } from "@/js/event-bus.js";

import CardActions from "@/components/core/CardActions.vue";
import ledger from "../finance/Ledger.vue";
export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
    ledger,
    "card-actions": CardActions,
  },
  data() {
    return {
      show2: false,
      show: false,
      options: [{ estado: "Nuevo" }, { estado: "Contactado" }, { estado: "En prueba" }, { estado: "Contratado" }, { estado: "Rechazado" }, { estado: "Despedido" }],
      fields: [
        { key: "cuenta", label: "Cuenta", sortable: true, sortDirection: "desc" },
        { key: "titulo", label: "titulo", sortable: true, class: "text-left" },
        { key: "saldo", label: "Saldo", sortable: true, class: "text-center" },
        { key: "ventas", label: "Ingreso", sortable: true, class: "text-center" },
        { key: "gastos", label: "Egreso", sortable: true, class: "text-center" },
        // { key: 'fecha_alta', label: 'fecha alta', sortable: true, class: 'text-center' },
        // { key: 'responsable', label: 'Responsable', sortable: true, class: 'text-center' },
        // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
        { key: "action", label: "Acciones", tdClass: "text-center" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      ///////
      table: "meca_cuentas",
      items: [],
      row: {},
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nueva cuenta",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      singleTable: "Cuenta",
      textTable: "Trabajo",
      rows: [],
      //
      cuenta: "",
      titulo: "",
      descripcion: "",
      account: "",
      accountSelected: "",
      cuentaini: "",
      cuentainiText: "",
      config: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    EventBus.$emit("isToogle", false);

    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");
    this.config = JSON.parse(localStorage.getItem("config"));

    this.getData();
    //await this.actualizaSaldos();
  },
  methods: {
    padDigits(number, digits) {
      return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
    },
    async verifyAccount(account) {
      try {
        let res = await this.$store.dispatch("get", { path: "meca_cuentas/getByAccount/" + account + "/" + this.company_id });
        return res;
      } catch (error) {
        return null;
      }
    },
    async actualizaSaldos() {
      this.$isLoading(true);

      let res = await this.$store.dispatch("post", { path: "actualizaSaldosPorEmpresa/" + this.company_id });
      await this.getData();
      console.log(res);
      this.$isLoading(false);
    },
    addItem() {
      this.rows.push({});
    },
    showModal2(item) {
      console.log("----->", item);
      this.account = item.cuenta;
      //this.$bvModal.show('exampleModalCenter2')
      //this.$router.push('accountStatus/'+this.account)
      const routerData = this.$router.resolve({ path: "accountStatus/" + this.account });
      window.open(routerData.href, "_blank");
    },
    showModal(item, id) {
      console.log("----->", item);
      if (id == 1) {
        this.modalTitle = "Nueva " + this.singleTable;
        this.row = {};
        this.$bvModal.show("exampleModalCenter");
      } else {
        this.row = item;
        this.modalTitle = "Editar " + this.singleTable;
        this.cuenta = this.row.cuenta;
        this.titulo = this.row.titulo;
        this.descripcion = this.row.descripcion;
        this.$bvModal.show("exampleModalCenter");
        console.log(this.row);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + "/getAllByCompany/" + this.company_id });
        if (res.length > 0) {
          this.items = res;
          this.totalRows = this.items.length;
        } else {
          this.items = [];
          this.totalRows = 0;
        }
      } catch (error) {
        this.items = [];
        this.totalRows = 0;
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        console.log("save");
        let data = { ...this.row };
        let res;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", { path: this.table + "/modify/" + this.row.id, data: data });
        } else {
          data.empresa_id = this.company_id;
          data.usuario_id = this.userId;
          let verificaCuenta = await this.verifyAccount(data.cuenta);
          if (verificaCuenta.length > 0) {
            console.log(verificaCuenta);
            this.$swal({ title: "Aviso", text: "Error: ya existe cuenta", icon: "error" });
            return;
          } else {
            res = await this.$store.dispatch("post", { path: this.table + "/new", data: data });
          }
        }
        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.getData();
          this.row = {};
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          let res = await this.$store.dispatch("delete", { path: this.table + "/delete/" + item.id });
          if (res.error) {
            this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          } else {
            this.getData();
            this.$swal({ title: "Aviso", text: "Eliminado correctamente", icon: "success" });
          }
        } catch (error) {
          this.items = [];
          console.error("error--->", error);
        }
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
.padding-none {
  padding: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.px-none {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.yellow {
  color: orange;
}
</style>
