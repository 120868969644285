<template>
  <div class="modal-body bg-gray">
    <form @submit.prevent="saveData">
      <div class="full pt-0">
        
        <div class="row" style="width: 105% !important;">
          <!-- categorias -->
          <div class="  col-sm-12  col-md-8">
            <div class="row pr-3">
              <div class="col-md-12 p-2 card">
                <input class="form-control mx-1" @keyup="buscar()" type="text" v-model="textoABuscar" name="" id="" placeholder="Texto a buscar..." />
              </div>
              <div class="">
                <div class="bg-primary pt-2">
                  <h4 class="mt-0 text-light">Categorías</h4>
                </div>
                <!-- <h4> panel cat y prod</h4> -->
                <div class="row">
                  <div class="inner only-this-horizon-scrollbar" style="">
                    <div class="scroll-container diff-size">
                      <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                      <div @click="getProductByCategory(item.id)" class="scroll-section" v-for="item in card_home" :key="item.id">
                        <categories-vue :product="item" class="zoom" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div class="row text-center pt-0 px-3">
                <div class="bg-primary py-0 mb-2">
                  <h4 class="mt-1 text-light">Productos</h4>
                </div>
                <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                <div v-if="productList == 0 && category_id != 0" class="bg-alert-primary mt-3">
                  <h5 class="text-alert pt-2"><i class="fa fa-exclamation-triangle"></i> No hay productos en esta categoría</h5>
                </div>
                <div v-if="category_id == 0" class="bg-alert-primary mt-3">
                  <h5 class="text-alert pt-2"><i class="fa fa-exclamation-triangle"></i> Selecciona una categoría</h5>
                </div>

                <div @click="showModal(item)" class="col-lg-3 py-1 col-md-4 col-sm-12  px-0   mx-0" v-for="item in productList" :key="item.id">
                  <product-vue :product="item" class="zoom" />
                </div>
              </div>
          </div>
         
          <!-- panel derecho -->
          <!-- <div class="col-md-4 pr-0 ">
            <div class="d-flex justify-content-end">
              <div class="  card text-right">
                <div clss="  px-3">
                  <div class="text-left mb-2 m-2">
                    <label for="estado" class="">Mesa/puesto</label>
                    <b-form-select required class="form-control select  " v-model="row.puesto_id" :options="listaPuestos" value-field="id" text-field="nombre">
                    </b-form-select>
                  </div>
                 
                <hr>
                
                  <div class="row mt-0 pt-0 mx-2 mb-2">
                    
                    
                    <div class="  ">
                      <div class="row bg-dark py-2  rounded">
                        <div class="col-6 text-left">
                          <label class="mb-0 pb-0">Sub-total:</label>
                        </div>
                        <div class="col-6 text-right"><label class="mb-0 pb-0" for="">{{ config.moneda + subtotal.toFixed(2) }}</label> </div>
                      </div>
                      <div class="row bg-dark py-2 my-2 rounded">
                        <div class="col-6 text-left">
                          <label class="mb-0 pb-0">Iva</label>
                        </div>
                        <div class="col-6 text-right"><label class="mb-0 pb-0" for="">{{ config.moneda + iva.toFixed(2) }}</label></div>
                      </div>
                      <div class="row bg-primary py-2 my-2 rounded">
                        <div class="col-6 text-left">
                          <b><span>TOTAL:</span></b>
                        </div>
                        <div class="col-6 text-right">
                          <b> {{ config.moneda + total.toFixed(2) }}</b>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mx-0 px-1   text-left">
                      <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0">
                        <span for="observaciones" class="h6">Iva</span>
                      </b-form-checkbox>
                    </div>
                    
                 
                  </div>
                  <hr  />
                  <div class="row m-2">
                    <div class="col-12 text-left mt-0">
                      <label for="categoria" class="">Cuenta origen</label>
                      <b-form-select required class="form-control select  " v-model="cuentaOrigen" :options="accountsList" value-field="cuenta" text-field="titulo">
                      </b-form-select>
                    </div>
                    <div class="col-12 text-left mt-2">
                      <label for="categoria" class="">Cuenta destino</label>
                      <b-form-select required class="form-control select  " v-model="cuentaDestino" :options="accountsList" value-field="cuenta" text-field="titulo">
                      </b-form-select>
                    </div>
                  </div>
                  <label class="text-left">Selecciona el método de pago</label>
                 
                  <div class="row px-4">
                    <div class="col-6 px-1  mt-2">
                      <div :class="buttons == 1 ? 'btn-block btn btn-primary py-2 text-center pointer' : 'btn-block btn btn-light py-2  text-center pointer'" @click="boton(1)">
                        
                        <i :class="buttons == 1 ? 'fa-solid fa-circle-check' : 'fa-solid fa-money-bill-transfer'" style="font-size: 2rem;"></i><br>
                        <span :class="buttons == 1 ? ' mt-3 bold' : ' mt-3'">Transferencia</span>
                      </div>
                    </div>
                    <div class="col-6 px-1 mt-2">
                      <div :class="buttons == 2 ? 'btn btn-primary btn-block py-2  text-center pointer' : 'btn btn-light btn-block py-2  text-center pointer'" @click="boton(2)">
                        
                        <i :class="buttons == 2 ? 'fa-solid fa-circle-check' : 'fa-solid fa-money-bills '" style="font-size: 2rem;"></i><br>
                        <span :class="buttons == 2 ? '  mt-3 bold' : ' mt-3'">Efectivo</span>
                      </div>
                    </div>
                    <div class="col-6 px-1 mt-2">
                      <div :class="buttons == 3 ? 'btn btn-primary  btn-block py-2 text-center pointer' : 'btn btn-light btn-block  py-2 text-center pointer'" @click="boton(3)">
                        
                        <i :class="buttons == 3 ? 'fa-solid fa-circle-check' : 'fa-solid fa-money-check '" style="font-size: 2rem;"></i><br>
                        <span :class="buttons == 3 ? '  mt-3 bold' : '  mt-3'">Tarjeta</span>
                      </div>
                    </div>
                    <div class="col-6 px-1 mt-2">
                      <div :class="buttons == 4 ? ' btn btn-block btn-primary py-2 text-center pointer' : 'btn-block btn btn-light   py-2 text-center pointer'" @click="boton(4)">
                        
                        <i :class="buttons == 4 ? 'fa-solid fa-circle-check' : 'fa-solid fa-ellipsis '" style="font-size: 2rem;"></i><br>
                    
                        <span :class="buttons == 4 ? '  mt-3 bold' : '  mt-3'">otros</span>
                      </div>
                    </div>
                  </div>
                  <hr class="hr mt-4" />
                  <div class="row my-3 px-3">
                    <div class="col-6">
                      <b-button @click="cleanSale()" class="btn btn-dark w-100">Cancelar</b-button>
                    </div>
                    <div class="col-6">
                      <b-button type="submit" class="btn btn-primary w-100">Guardar</b-button>
                    </div>
                  </div>
                </div>
              </div>  
            </div>
          </div> -->
          <div class="col-sm-12 col-md-4 span6 bg-card text-center">
            <div class="row bg-card  pb-2 pt-3">
              <div class="col-md-12  mb-2 text-left">
                <label for="nombre" class="">Fecha de compra:</label>
                <input v-model="purchase.fecha_compra" class="form-control" type="date" name="nombre" id="nombre" />
              </div>
              <div class="col-md-12 mb-2 text-left">
                <label for="nombre" class="">Fecha de pago:</label>
                <input v-model="purchase.fecha_pago" class="form-control" type="date" name="nombre" id="nombre" />
              </div>
              <div class="col-md-12 mb-2 text-left">
                <label for="estado" class="">Proveedor:</label>
                <b-form-select required class="form-control select" v-model="purchase.proveedor_id" :options="providers" value-field="id" text-field="nombre"> </b-form-select>
              </div><div class="col-md-12  text-center">
                <button   class="btn btn-primary mt-1 mb-1 btn-block" @click.prevent="agregarProducto()" type="button">Agregar producto</button>
              </div>

            </div>
            <div class="">
             
              <div class=" ">
                <b-list-group>
                 
                  <div v-for="it of cart" :key="it.id" button>
                    <div class="px-3 hover">
                      <div class="border-top border-bottom row py-3 hover">
                       
                        <div class="col-6 text-left"><i @click="deleteProduct(it)" class="fa fa-trash-o text-red" /> {{ it.nombre + "/$" + it.precio_compra }}</div>
                        <div class="col-2 text-left">{{ it.cantidad }}</div>
                        <div class="col-3 text-left">{{ config.moneda + (it.precio_compra * it.cantidad).toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </b-list-group>
                <div class="row mt-3  ">
                  <div class="col-md-12 mb-2 mt-2 text-left">
                    <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0">
                      <span for="observaciones" class="h6">Iva</span>
                    </b-form-checkbox>
                  </div>
                  <div class="row">
                    <div class="col-6 text-left mb-1"><span>sub-total:</span></div>
                    <div class="col-6 text-left mb-1">{{ config.moneda + subtotal.toFixed(2) }}</div>
                    <div class="col-6 text-left mb-1">iva:</div>
                    <div class="col-6 text-left mb-1">{{ config.moneda + iva.toFixed(2) }}</div>
                    <div class="col-6 text-left mb-1" style="font-size: 16px"><b>TOTAL:</b></div>
                    <div class="col-6 text-left mb-1" style="font-size: 16px">
                      <b>{{ config.moneda + total.toFixed(2) }}</b>
                    </div>
                  </div>
                  <hr class="hr my-2" />
                  <div class="col-12 text-left">
                    <label for="categoria" class="">Referencia:</label>
                    <input class="form-control" type="text" v-model="purchase.referencia" name="" id="" />
                  </div>
                  <div class="col-12 text-left pt-2">
                    <label for="categoria" class="">Estado:</label>
                    <b-form-select required class="form-control select" v-model="purchase.estado" :options="estados" value-field="nombre" text-field="nombre"> </b-form-select>
                  </div>
                </div>
               
                <div class="row mt-3 p-3">
                  <div class="col-6">
                    <b-button @click="cleanSale()" class="btn btn-dark w-100">Cancelar</b-button>
                  </div>
                  <div class="col-6">
                    <b-button type="submit" class="btn btn-primary w-100">Guardar</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
           
        </div>

        <b-modal id="exampleModalCenter" tabindex="-1" class="modal-lg" centered title="Agregar producto" hide-footer>
          <template v-slot:modal-header-close>
            <button type="button" class="btn btn-close"></button>
          </template>
          <div class="modal-body bg-gray">
            <form @submit.prevent="addProductToCart()">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <label for="nombre" class="">Producto:</label>
                  <input readonly v-model="row.nombre" class="form-control" type="text" name="nombre" id="nombre" />
                </div>
                <div class="col-md-12 mb-2">
                  <label for="nombre" class="">Descripción:</label>
                  <input v-model="row.descripcion" class="form-control" type="text" name="descripcion" id="descripcion" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="nombre" class="">Cantidad:</label>
                  <input type="number" step="0.01" min="0.01" v-model="row.cantidad" class="form-control" name="cantidad" id="cantidad" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="nombre" class="">Precio compra: {{ config.moneda }}</label>
                  <input required step="0.01" min="0.01" v-model="row.precio_compra" class="form-control" type="text" name="precio_compra" id="precio_venta" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="nombre" class="">Precio venta: {{ config.moneda }}</label>
                  <input required step="0.01" min="0.01" v-model="row.precio_venta" class="form-control" type="text" name="precio_venta" id="precio_venta" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="nombre" class="">Total:</label>
                  <input
                    readonly
                    required
                    :value="config.moneda + row.precio_compra * (row.cantidad ? row.cantidad : 1)"
                    class="form-control"
                    type="text"
                    name="precio_compra"
                    id="precio_compra"
                  />
                </div>
              </div>
              <div class="text-right">
                <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cancelar</b-button>
                <input class="btn btn-primary mr-2" type="submit" value="Agregar" />
              </div>
            </form>
          </div>
        </b-modal>
      </div>
      <div class="movil">
        <div class="row border mb-3">
          <div class="col-md-12 pt-0">
            <div class="row py-2 px-1">
              <div class="col-md-4 mb-2">
                <label for="nombre" class="">Fecha de compra:</label>
                <input v-model="purchase.fecha_compra" class="form-control" type="date" name="nombre" id="nombre" />
              </div>
              <div class="col-md-4 mb-2">
                <label for="nombre" class="">Fecha de pago:</label>
                <input v-model="purchase.fecha_pago" class="form-control" type="date" name="nombre" id="nombre" />
              </div>
              <div class="col-md-4 mb-2">
                <label for="estado" class="">Proveedor:</label>
                <b-form-select required class="form-control select" v-model="purchase.proveedor_id" :options="providers" value-field="id" text-field="nombre"> </b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- categorias -->
          <div class="bg-primary mt-3 py-1">
            <h4 class="mt-1 text-light text-center">Categorias</h4>
          </div>
          <div class="col-12 p-0 border-2">
            <!-- <h4> panel cat y prod</h4> -->
            <div class="p-0">
              <div class="inner p-0 only-this-horizon-scrollbar" style="">
                <div class="scroll-container diff-size">
                  <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
                  <div @click="getProductByCategory(item.id)" class="scroll-section" v-for="item in card_home" :key="item.id">
                    <categories-vue :product="item" class="zoom" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- panel derecho -->

          <!-- tabla productos -->
          <div class="bg-primary mt-3 py-1 ">
            <h4 class="mt-1 text-light text-center">Productos</h4>
          </div>
          <div v-if="productList == 0 && category_id != 0" class="bg-alert-primary mt-5">
            <h6 class="text-alert text-center pt-2"><i class="fa fa-exclamation-triangle"></i> No hay productos en esta categoría</h6>
          </div>
          <div v-if="category_id == 0" class="bg-alert-primary mt-5">
            <h6 class="text-alert text-center pt-2"><i class="fa fa-exclamation-triangle"></i> Selecciona una categoría</h6>
          </div>
          <div class="mt-3 p-1">
            <!-- <div class="col-3" v-for="product in products" :key="product.id"> -->
            <div @click="showModal(item)" class="px-0" v-for="item in productList" :key="item.id">
              <product-vue :product="item" class="zoom py-2" />
            </div>
          </div>
          <div class="col-sm-12 pr-0 pl-0 pl-lg-3 mt-4 col-md-4 span6 text-center">
            <div class="ml-1">
              <div class="bg-primary py-1">
                <h4 class="mt-1 text-light">Resumen</h4>
              </div>
              <div class="border">
                <b-list-group>
                  <!-- <b-list-group-item v-for="it of cart" :key="it.id" button>{{it.nombre + ' ' + it.cantidad + ' ' + it.precio_venta}}</b-list-group-item> -->
                  <div v-for="it of cart" :key="it.id" button>
                    <div class="px-3 hover">
                      <div class="border-top border-bottom row py-3 hover">
                        <!-- <div @click="deleteProduct(it)" class="col-1 text-right m0"></div> -->
                        <div class="col-6 text-left"><i @click="deleteProduct(it)" class="fa fa-trash-o text-red" /> {{ it.nombre + "/" + config.moneda + it.precio_venta }}</div>
                        <div class="col-1">{{ it.cantidad }}</div>
                        <div class="col-2 text-left">{{ config.moneda + (it.precio_venta * it.cantidad).toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </b-list-group>
                <div class="row mt-3 px-3">
                  <div class="col-md-12 mb-2 mt-2 text-left">
                    <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0">
                      <span for="observaciones" class="h6">Iva</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 text-left mb-1"><span>sub-total:</span></div>
                  <div class="col-6 text-left mb-1">{{ config.moneda + subtotal.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1">iva:</div>
                  <div class="col-6 text-left mb-1">{{ config.moneda + iva.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px"><b>TOTAL:</b></div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px">
                    <b>{{ config.moneda + total.toFixed(2) }}</b>
                  </div>
                  <hr class="hr my-2" />
                  <div class="col-12 text-left">
                    <label for="categoria" class="">Referencia:</label>
                    <input class="form-control" type="text" v-model="purchase.referencia" name="" id="" />
                  </div>
                  <div class="col-12 text-left">
                    <label for="categoria" class="">Estado:</label>
                    <b-form-select required class="form-control select" v-model="purchase.estado" :options="estados" value-field="nombre" text-field="nombre"> </b-form-select>
                  </div>
                </div>
                <!-- <div class="row mt-3">
                                    <span>Cliente:</span>
                                    <div class="col-2">
                                        <b-button class="btn btn-dark w-10" @click="gotoClientes()">+</b-button>
                                    </div>
                                    <div class="col-10">
                                        <input class="form-control" type="text" v-model="nombreCliente" name="" id="">
                                    </div>
                                </div> -->
                <div class="row mt-3 p-3">
                  <div class="col-6">
                    <b-button @click="cleanSale()" class="btn btn-dark w-100">Cancelar</b-button>
                  </div>
                  <div class="col-6">
                    <b-button type="submit" class="btn btn-primary w-100">Guardar</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <b-modal id="modalProd" tabindex="-1" class="" size="lg" centered title="Nuevo Producto" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveDataProduct()">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <label for="nombre" class="">Nombre</label><input required v-model="rowProducto.nombre" class="form-control" type="text" name="nombre" id="nombre" />
                </div>
                <!-- <div class="col-md-4 mb-2">
                  <label for="codigo" class="">Código de barras</label><input required v-model="row.codigo" class="form-control" type="text" name="codigo" id="codigo" />
                </div> -->
                <div class="col-md-4 mb-2">
                  <label for="precio compra" class="">Prec. compra ({{ config.moneda }})</label
                  ><input step="0.01" required class="form-control" v-model="rowProducto.precio_compra" type="number" name="precio compra" id="precio compra" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="precio venta" class="">Prec. venta ({{ config.moneda }})</label
                  ><input step="0.01" required class="form-control" v-model="rowProducto.precio_venta" type="number" name="precio venta" id="precio venta" />
                </div>
                <div class="col-md-4 mb-2">
                  <label for="existencia" class="">Existencia</label
                  ><input required class="form-control" v-model="rowProducto.existencia" type="number" name="existencia" id="exisistencia" />
                </div>
                <div class="col-md-6 mb-2">
                  <label for="descripcion" class="">Descripción</label
                  ><input class="form-control" v-model="rowProducto.descripcion" type="text" name="descripcion" id="descripcion" />
                </div>
                <div class="col-md-6 mb-2">
                  <label for="categoria" class="">Categoría</label>
                  <b-form-select required class="form-control select" v-model="rowProducto.categoria_id" :options="card_home" value-field="id" text-field="nombre"> </b-form-select>
                </div>
                <div class="col-md-12 mb-2 mt-4 ml-4">
                  <b-form-checkbox size="lg" id="checkbox-2" v-model="rowProducto.visible_en_ventas" name="checkbox-2" value="1" unchecked-value="0">
                    <span for="" class="h6">visible en ventas</span>
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('modalProd')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ProductVue from "./ProductPurchase.vue";
import CategoriesVue from "./Category.vue";

export default {
  components: { ProductVue, CategoriesVue },
  data() {
    return {
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        { key: "cliente", label: "Cliente", sortable: true, class: "text-left" },
        { key: "fecha", label: "Fecha ", sortable: true, class: "text-left" },
        { key: "sucursal", label: "Sucursal", sortable: true, class: "text-left" },
        { key: "total_venta", label: "Total Venta ", sortable: true, class: "text-right" },
        { key: "total_costos", label: "Total costos ", sortable: true, class: "text-right" },
        { key: "Margen", label: "Margen", sortable: true, class: "text-right" },
        { key: "estado", label: "Estado", sortable: true, class: "text-right" },
        { key: "action", label: "", tdClass: "text-center" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      fields2: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        { key: "articulo", label: "Articulo", sortable: true, class: "text-center" },
        { key: "cantidad", label: "Cantidad ", sortable: true, class: "text-center" },
        { key: "precio_unit", label: "Precio unit", sortable: true, class: "text-center" },
        { key: "sub_total", label: "Sub total ", sortable: true, class: "text-center" },

        { key: "action", label: "acción", tdClass: "dt-body-right" },
        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      items: [],
      row: {},
      card_home: [],
      table: "meca_categorias",
      company_id: 0,
      userId: 0,
      rol: 0,
      productList: [],
      cart: [],
      estados: [{ nombre: "Pagada" }, { nombre: "Pendiente" }],
      subtotal: 0,
      total: 0,
      iva: 0,
      con_iva: 1,
      cliente_id: 0,
      nombreCliente: "Cliente Genérico",
      providers: [],
      purchase: {},
      referencia: "",
      category_id: 0,
      config: {},
      textoABuscar: "",
      allProducts: [],
      rowProducto: {},
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.cleanSale();
      this.userId = localStorage.getItem("user_id");
      this.company_id = localStorage.getItem("company_id");
      this.rol = localStorage.getItem("rol");
      this.config = JSON.parse(localStorage.getItem("config"));

      await this.getCategories();
      await this.getProvider();
      await this.getallProducts();
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
    }
  },
  methods: {
    async saveDataProduct() {
      try {
        // let uploadImg;
        // let urlimg = this.row.url;
        // console.log("filename1 != null ???", uploadImg);
        this.$isLoading(true);
        // if (this.filename1 != null) {
        //   console.log("entró en el if");
        //   this.filename1 = this.company_id + "_" + this.filename1;
        //   uploadImg = await this.$store.dispatch("subirarchivosCustom", { filename: this.filename1, file: this.file1, type: "products" });
        //   console.log("uploadimg ", uploadImg);
        //   if (uploadImg == true) {
        //     console.log("entró en el if 2 es true");
        //     urlimg = "https://admin.siscoper.net/img/products/" + this.filename1;
        //   }
        // }
        console.log("save");
        let data = Object.assign({}, this.rowProducto);
        data.empresa_id = this.company_id;
        // data.url = urlimg;
        let res;

        res = await this.$store.dispatch("post", { path: "meca_productos/new", data: data });

        this.$isLoading(false);
        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          await this.getallProducts();
          this.row = {};
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.filename1 = null;
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    agregarProducto() {
      this.rowProducto = { visible_en_ventas: 0 };
      this.$bvModal.show("modalProd");
    },
    async getallProducts() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "meca_productos/getAllProducts/" + this.company_id,
        });
        this.$isLoading(false);
        console.log("---", res);
        if (res.length > 0) {
          this.allProducts = res;
        } else {
          this.allProducts = [];
        }
      } catch (error) {
        this.allProducts = [];
        console.error("error", error);
      }
    },
    buscar() {
      //textoABuscar
      if (this.textoABuscar.length > 0) {
        let resultadoFiltro = this.allProducts.filter((x) => x.nombre.toUpperCase().trim().includes(this.textoABuscar.toUpperCase().trim()));
        if (resultadoFiltro.length > 0) {
          this.productList = resultadoFiltro;
        } else {
          this.productList = [];
        }
      } else {
        this.productList = this.productListBk;
      }
    },
    cleanSale() {
      this.purchase.estado = "Pagada";
      this.purchase.fecha_compra = this.$moment().utc().format("YYYY-MM-DD");
      this.purchase.fecha_pago = this.$moment().utc().format("YYYY-MM-DD");
      this.cart = [];
      this.productList = [];
      this.calculateTotal();
    },
    gotoClientes() {},
    deleteProduct(item) {
      let tt = this.cart.indexOf(item);
      console.log(tt);
      this.cart.splice(tt, 1);
      this.calculateTotal();
      //alert()
    },
    calculateTotal() {
      this.subtotal = this.cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0);
      if (this.con_iva == 1) {
        this.iva = this.subtotal * 0.15;
      } else {
        this.iva = 0;
      }
      this.total = this.subtotal + this.iva;
    },
    addProductToCart() {
      console.log("add");
      this.cart.push(this.row);
      this.row = {};
      this.row.estado = "Pagada";
      this.row.fecha_compra = this.$moment().utc().format("YYYY-MM-DD");
      this.row.fecha_pago = this.$moment().utc().format("YYYY-MM-DD");

      this.calculateTotal();
      this.$bvModal.hide("exampleModalCenter");
    },
    showModal(item) {
      this.row = Object.assign({}, item);
      this.row.cantidad = 1;
      this.$bvModal.show("exampleModalCenter");
    },
    async getProductByCategory(item) {
      console.log("getProductByCategory");
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_productos/getAllByCategoryCompany/" + this.company_id + "/" + item });
        this.$isLoading(false);
        this.category_id = item.id;
        if (res.length > 0) {
          console.log(this.productList);
          this.productList = res;
        } else {
          this.productList = [];
        }
      } catch (error) {
        this.productList = [];
        console.error("error", error);
      }
    },
    async getProvider() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_proveedores/getAllByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.providers = res;
        }
      } catch (error) {
        this.providers = [];
        console.error("error", error);
      }
    },
    async getCategories() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: this.table + "/getAllByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.card_home = res;
        }
      } catch (error) {
        this.card_home = [];
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        console.log("save");
        if (this.cart.length == 0) {
          this.$swal({ title: "Aviso", text: "Debes seleccionar un producto.", icon: "error" });
          return;
        }
        //let data = Object.assign({} , this.row)
        //data.empresa_id = this.company_id
        let res;
        this.$isLoading(true);

        // total:this.total,
        // empresa_id: this.company_id,
        // usuario_id: this.userId,
        // estado: 'Pagada',
        // por_iva: 15,
        // fecha_i: this.$moment().utc(),
        // fecha_f: this.$moment().utc(),
        this.purchase.empresa_id = this.company_id;
        this.purchase.usuario_id = this.userId;
        this.purchase.total = this.total;
        this.purchase.iva = this.iva;
        let data = {
          purchase: this.purchase,
          detail: this.cart,
        };
        res = await this.$store.dispatch("post", { path: "meca_compras/new", data: data });
        this.$isLoading(false);

        if (res.error) {
          this.$isLoading(false);
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.cleanSale();
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          let res = await this.$store.dispatch("delete", { path: this.table + "/delete/" + item.id });
          if (res.error) {
            this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          } else {
            this.getData();
            this.$swal({ title: "Aviso", text: "Eliminado correctamente", icon: "success" });
          }
        } catch (error) {
          this.items = [];
          console.error("error--->", error);
        }
      }
    },
  },
};
</script>

<style>
.hover {
}
.hover:hover {
  background-color: rgba(75, 133, 105, 0.034);
}

.border-2 {
  border-radius: 5px;
  /* padding:5px; */
  text-align: center !important;
}

/* carusel */
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 98%;

  scroll-snap-type: mandatory;
  scroll-behavior: smooth;

  word-wrap: break-word !important;
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;

  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}

.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}
.card-col {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cel_big2 {
  right: 0px;
  /* border:1px solid red; */
  /* //height: 100% !important; */
  /* background-color: rgb(231, 231, 231); */
  position: absolute;
  height: 800px;
  bottom: 10px;
  top: 100px;
  min-height: 600px;
}
.hr {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.text-red {
  color: red;
}
.movil {
  display: none;
}
.full {
  display: block;
}
@media screen and (max-width: 720px) {
  .movil {
    display: block;
  }
  .full {
    display: none;
  }
  .cel_big2 {
    position: static;
    min-height: 0px !important;
  }
}
@media screen and (max-width: 1440px) and (min-width: 720px) {
  .col-md-1 {
    width: 8.33%;
  }
  .col-md-2 {
    width: 16.33%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33%;
  }
  .col-md-5 {
    width: 41.33%;
  }
  .col-md-6 {
    width: 49%;
  }
  .col-md-7 {
    width: 57.33%;
  }
  .col-md-8 {
    width: 65.33%;
  }
  .col-md-9 {
    width: 73%;
  }
  .col-md-10 {
    width: 81.33%;
  }
  .col-md-11 {
    width: 89.33%;
  }
  .col-md-12 {
    width: 100%;
  }
}
</style>
