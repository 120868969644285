<template>
  <div class="container-fluid login-container">
    <div class="row login-card bg-card flex-md-row flex-column">
      <!-- Sección Izquierda -->
      <div class="col-md-6 left-section bg-primary p-5 full">
        <h1 class="text-primary-light pt-5">
          <b v-if="isSignupClicked">¡Regístrate ahora!</b>
          <b v-else>¡Hola de nuevo!</b>
        </h1>
        <p class="text-light" v-if="isSignupClicked">
          Nos alegra que quieras unirte. Llena tus datos para crear tu cuenta y empezar a disfrutar de nuestros servicios.
        </p>
        <p class="text-light" v-else>
          Vuelve a conectarte con nosotros. Ingresa tus datos y sigue disfrutando de todo lo que tenemos para ofrecerte. ¡Nos encanta tenerte de vuelta!
        </p>
        <div class="pt-3">
          <!-- El botón cambia su texto solo en la vista de registro -->
          <button @click="handleButtonClick" class="btn btn-light">
            {{ isSignupClicked ? 'Volver a Login' : '¿Nuevo aquí? Crea una cuenta' }}
          </button>
        </div>
      </div>

      <!-- Sección Derecha -->
      <div class="col-md-6 right-section">
        <slot></slot>
        <!-- Enlace para restablecer contraseña -->
        <router-link
          class="text-decoration-none text-primary"
          to="/authentication/auth-password-reset"
        >
          ¿Olvidaste tu contraseña?
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export const eventBus = new Vue();

export default {
  name: 'AuthenticationLayout',
  data() {
    return {
      isSignupClicked: false, // Indica si estamos en la vista de registro
    };
  },
  watch: {
    // Detecta cambios en la ruta para saber si estamos en registro
    $route(to) {
      this.isSignupClicked = to.name === 'register';
    }
  },
  methods: {
    handleButtonClick() {
      // Si estamos en la vista de registro, volvemos al login
      if (this.isSignupClicked) {
        this.$router.push('/authentication/auth-login');
      } else {
        // Si estamos en login, redirigimos a la vista de registro
        this.$router.push('/authentication/auth-signup');
      }
    }
  }
};
</script>
<style>
body {
           
        }
        .login-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }
        .login-card {
            border-radius: 15px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            /* background-color: white; */
            /* max-width: 900px; */
            width: 95%;
        }
         
        .left-section h2 {
            font-weight: bold;
            margin-bottom: 20px;
        }
        .circle-image {
            border-radius: 50%;
            margin: 10px;
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
        .right-section {
            padding: 40px;
        }
        .social-icons a {
            margin: 0 10px;
            font-size: 24px;
            color: gray;
        }
        @media (max-width: 768px) {
            .left-section {
                border-radius: 15px 15px 0 0;
                padding: 20px;
            }
            .login-card {
                flex-direction: column;
                border-radius: 15px;
            }
        }
</style>