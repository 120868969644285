<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card px-3 mb-4">
        <div class="row justify-content-between">
          <div class="img-bg text-light  projects card-header d-flex flex-wrap justify-content-between align-items-center  ">
            <div>
              <p class="h3 pb-2">Proyectos</p>
              <button @click="showModal('', 1)" class="btn btn-primary mr-2"><i class="fa fa-plus mr-2"></i>Agregar Proyecto</button>
            </div>
            <card-actions></card-actions>
          </div>

          <div class="col-md-5">
            <b-form-group label="Por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(fecha_inicio)="data">
            <span>{{ data.item.fecha_fin != null ? $moment(data.item.fecha_inicio).utc().format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(fecha_fin)="data">
            <span>{{ data.item.fecha_fin != null ? $moment(data.item.fecha_fin).utc().format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(action)="data">
            <b-button v-if="data.item.kanban_id != null" variant="warning" class="p-1 ml-1" @click="$router.push({ name: 'kanban', params: { id: data.item.kanban_id } })"
              ><i class="fa fa-list"></i
            ></b-button>
            <span
              ><button @click="showModal(data.item, 2)" class="btn btn-primary ml-1 p-1"><i class="fa fa-pencil"></i></button>
            </span>
            <span
              ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1 p-1"><i class="fa fa-trash-o"></i></button>
            </span>

            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
            <!-- <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

            <!-- <table-action></table-action> -->
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            hide-goto-end-buttons
            hide-ellipsis
            prev-text="Prev"
            next-text="Next"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal id="exampleModalCenter" tabindex="-1" class="" size="lg" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-9 mb-2">
              <label for="nombre" class="">Nombre</label>
              <input required v-model="row.titulo" class="form-control" type="text" name="nombre" id="nombre" />
            </div>
            <div class="col-md-3 mb-2">
              <label for="nombre" class="">Estado</label>
              <b-form-select required text-field="estado" value-field="estado" class="form-select input-size-lg" v-model="row.estado" :options="listaEstado"></b-form-select>
            </div>
            <div class="col-md-12 mb-2"><label for="nombre" class="">Descripción</label><b-form-textarea v-model="row.descripcion" id="textarea-default"></b-form-textarea></div>

            <div class="col-md-6 mb-2">
              <label for="precio compra" class="">Fecha inicio</label
              ><input required class="form-control" v-model="row.fecha_inicio" type="date" name="precio compra" id="precio compra" placeholder="0" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="precio venta" class="">Fecha fin</label
              ><input required class="form-control" v-model="row.fecha_fin" type="date" name="precio venta" id="precio venta" placeholder="0" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="existencia" class="">Presupuesto</label
              ><input required class="form-control" v-model="row.presupuesto" type="text" name="existencia" id="presupuesto" placeholder="0" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="descripcion" class="">Duración horas</label
              ><input class="form-control" v-model="row.duracion_horas" type="text" name="duracion" id="duracion" placeholder="0" />
            </div>

            <div class="col-md-6 mb-2">
              <label for="existencia" class="">Horas trabajadas</label
              ><input class="form-control" v-model="row.horas_trabajadas" type="text" name="existencia" id="exisistencia" placeholder="0" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="descripcion" class="">Cliente</label
              ><input class="form-control" v-model="row.cliente_id" type="text" name="descripcion" id="descripcion" placeholder="0" />
            </div>
          </div>
          <div class="text-right mt-3">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
            <b-button v-if="row.kanban_id == null && row.id" variant="warning" class="mr-2" @click="crearTablero(row)">Crear tablero</b-button>
            <b-button v-if="row.kanban_id != null && row.id" variant="warning" class="mr-2" @click="$router.push({ name: 'kanban', params: { id: row.kanban_id } })"
              >Ir al tablero</b-button
            >
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { EventBus } from "@/js/event-bus.js";

import CardActions from "@/components/core/CardActions.vue";
export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
    "card-actions": CardActions,
  },
  data() {
    return {
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        { key: "titulo", label: "Titulo", sortable: true, class: "text-center" },
        { key: "fecha_inicio", label: "fecha inicio", sortable: true, class: "text-center" },
        { key: "fecha_fin", label: "fecha fin", sortable: true, class: "text-center " },
        { key: "estado", label: "Estado", sortable: true, class: "text-center " },

        { key: "action", label: "", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      ///////
      table: "proyectos",
      items: [],
      row: {},
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo proyecto",
      listaEstado: [{ estado: "Negociación" }, { estado: "Aprobado" }, { estado: "En proceso" }, { estado: "Finalizado" }, { estado: "Cancelado" }, { estado: "Rechazado" }],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    EventBus.$emit("isToogle", false);

    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");

    this.getData();
  },
  methods: {
    async crearTablero(row) {
      let data = {
        empresa_id: this.company_id,
        titulo: row.titulo,
        usuario_id: this.userId,
      };
      let res = await this.$store.dispatch("post", { path: "meca_kanban/new", data: data });
      console.log("creando tablero", res);
      this.row.kanban_id = res.insertId;
      await this.saveData();
    },
    showModal(item, id) {
      console.log("----->", item);
      if (id == 1) {
        this.modalTitle = "Nuevo proyecto";
        this.row = {};
        this.$bvModal.show("exampleModalCenter");
      } else {
        this.row = item;
        this.modalTitle = "Editar proyecto";
        this.row.fecha_inicio = this.$moment(item.fecha_inicio).utc().format("YYYY-MM-DD");
        this.row.fecha_fin = this.$moment(item.fecha_fin).utc().format("YYYY-MM-DD");
        this.$bvModal.show("exampleModalCenter");
        console.log(this.row);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: this.table + "/getAllByCompany/" + this.company_id });
        if (res.length > 0) {
          this.items = res;
          this.totalRows = this.items.length;
        } else {
          this.items = [];
          this.totalRows = 0;
        }
      } catch (error) {
        this.items = [];
        this.totalRows = 0;
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        console.log("save");
        let data = Object.assign({}, this.row);
        data.empresa_id = this.company_id;
        let res;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", { path: this.table + "/modify/" + this.row.id, data: data });
        } else {
          data.usuario_id = this.userId;
          res = await this.$store.dispatch("post", { path: this.table + "/new", data: data });
        }
        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.getData();
          this.row = {};
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      try {
        console.log("eliminando...");
        const { isConfirmed } = await this.$swal({
          title: "Confirmar",
          text: "¿Desea realmente eliminar el registro?",
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
          reverseButtons: true,
        });
        if (isConfirmed) {
          console.log("confirmado...");
          try {
            let res = await this.$store.dispatch("delete", { path: this.table + "/delete/" + item.id });
            console.log("res", res);
            if (res.error) {
              this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
            } else {
              this.$swal({ title: "Aviso", text: "Eliminado correctamente", icon: "success" });
            }
            this.getData();
          } catch (error) {
            this.items = [];
            console.error("error--->", error);
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped></style>
