<template>
  <div class="row clearfix">
    <b-modal title="Selecciona color" id="modal-color" size="sm">
      <div class="row">
        <color-panel style="width: 100%" v-model="myEventColor"></color-panel>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="secondary" size="sm" class="float-right" @click="$bvModal.hide('modal-color')"> Cerrar </b-button>
        </div>
      </template>
    </b-modal>

    <div class="col-md-12">
      <h5 class="mt-0">CONFIGURACION USUARIO</h5>
      <form @submit.prevent="saveDataUser()">
        <div class="card mb-4 p-4">
          <div class="row">
            <div class="col-md-3 text">
              <label for="cantidad" class="">Color Usuario</label>
              <input required class="form-control" type="text" v-model="myEventColor" placeholder="Color" :style="'background-color:' + myEventColor" />
            </div>
            <div class="col-md-3 mt-3">
              <button @click.prevent="$bvModal.show('modal-color')" class="m-2 btn btn-light">Seleccionar color</button>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary lift text-uppercase">GUARDAR</button>
      </form>

      <h5 class="mt-5">CONFIGURACION GENERAL</h5>
      <form @submit.prevent="saveData()">
        <div class="card p-4 mb-4">
          <div class="row">
            <div class="col-md-3 text">
              <label for="cantidad" class="">Cuenta clientes</label>
              <input required class="form-control" type="number" v-model="config.cuenta_cliente" placeholder="cuenta clientes" />
            </div>
            <div class="col-md-3 text">
              <label for="cantidad" class="">Cuenta ventas</label>
              <input required class="form-control" type="number" v-model="config.cuenta_ventas" placeholder="cuenta ventas" />
            </div>
            <div class="col-md-3 text">
              <label for="cantidad" class="">Cuenta iva </label>
              <input required class="form-control" type="number" v-model="config.cuenta_iva" placeholder="cuenta iva" />
            </div>
            <div class="col-md-3">
              <label for="cantidad" class="">Cuenta gastos</label>
              <input required class="form-control" type="number" v-model="config.cuenta_gastos" placeholder="cuenta gastos" />
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-3 text">
              <label for="cantidad" class="">% Comisión Vendedor</label>
              <input required class="form-control" type="number" v-model="config.porcenta_comision" placeholder="Comisión Vendedor" />
            </div>
            <div class="col-md-3 text">
              <label for="cantidad" class="">% iva</label>
              <input required class="form-control" type="number" v-model="config.iva" placeholder="% iva" />
            </div>
            <div class="col-md-3 text">
              <label for="cantidad" class=""> Moneda </label>
              <input required class="form-control" type="text" v-model="config.moneda" placeholder="moneda" />
            </div>
            <div class="col-md-3">
              <label for="cantidad" class="">Nombre moneda</label>
              <input required class="form-control" type="text" v-model="config.moneda_nombre" placeholder="nombre moneda" />
            </div>
          </div>
        </div>
        <!-- <b-button class="btn btn-dark mr-2">GUARDAR</b-button> -->
        <button type="submit" class="btn btn-primary lift text-uppercase">GUARDAR</button>
      </form>
      <h5 class="mt-5">CONFIGURACION EMPRESA</h5>
      <form @submit.prevent="saveDataCompany()">
        <div class="card p-4 mb-4">
          <div class="row">
            <div class="col-md-3 text">
              <label for="cantidad" class="">Nombre empresa</label>
              <input required class="form-control" type="text" v-model="company.nombre" placeholder="Nombre" />
            </div>
            <div class="col-md-3 text">
              <label for="cantidad" class="">Dirección</label>
              <input class="form-control" type="text" v-model="company.direccion" placeholder="Dirección" />
            </div>
            <div class="col-md-3 text">
              <label for="cantidad" class="">Teléfono </label>
              <input class="form-control" type="text" v-model="company.telefono" placeholder="Teléfono" />
            </div>
            <div class="col-md-3 text">
              <label for="cantidad" class="">Móvil </label>
              <input class="form-control" type="text" v-model="company.pais" placeholder="Móvil" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-3 text">
              <label for="cantidad" class="">Cant. max. Usuarios </label>
              <label class="form-control">{{ company.usuarios }} </label>
              <button type="submit" class="btn btn-primary lift text-uppercase mt-3">GUARDAR</button>
              <!-- <input class="form-control" type="text" v-model="company.pais" placeholder="Móvil" /> -->
            </div>
            <div class="col-md-4">
              <label for="cantidad" class="">Logo</label>
              <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('company')" /><br />
              <input class="form-control" v-model="company.picture" type="text" name="url" id="url" />
            </div>
            <div class="col-md-4">
              <img :src="company.picture" width="200" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { ColorPicker, ColorPanel } from "one-colorpicker";
import Vue from "vue";

Vue.use(ColorPanel);
Vue.use(ColorPicker);

export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
  },
  data() {
    return {
      config: {
        id: 0,
        cuenta_cliente: "",
        cuenta_ventas: "",
        cuenta_iva: "",
        nasiento: "",
        iva: 0,
        moneda: "",
        moneda_nombre: "",
        cuenta_gastos: "",
        tipo_cuenta: "",
      },
      company: {
        nombre: "",
        direccion: "",
        telefono: "",
        picture: "",
      },
      filename1: null,
      file1: null,
      user: {},
      myEventColor: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");
    await this.getData();
    await this.getDataCompany();
    await this.getDataUser();
    this.myEventColor = this.user.color;
  },
  methods: {
    async onFileSelected(type) {
      try {
        this.file1 = event.target.files[0];
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        // this.filename1 = type + "_" + this.filename1 + ".jpg";
        let extension = this.file1.name.split(".").pop();
        this.filename1 = type + "_" + this.filename1 + "." + extension;
        console.log(this.filename1);
      } catch (error) {
        console.log("error", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: "meca_configuracion/get/" + this.company_id });
        this.config = res;
        console.log("items", this.items);
        console.log(res);
      } catch (error) {
        this.config = [];

        console.error("error", error);
      }
    },
    async getDataCompany() {
      try {
        let res = await this.$store.dispatch("get", { path: "empresas/getById/" + this.company_id });
        this.company = res[0];
        console.log("item company", this.company);
        // console.log(res);
      } catch (error) {
        this.company = [];
        console.error("error", error);
      }
    },
    async getDataUser() {
      try {
        let res = await this.$store.dispatch("get", { path: "usuarios/get/" + this.userId });
        this.user = res;
      } catch (error) {
        this.user = [];
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        console.log("save");
        let data = Object.assign({}, this.config);
        //data.empresa_id = this.company_id;
        let res;
        delete data.id;
        res = await this.$store.dispatch("put", { path: "meca_configuracion/modify/" + this.config.id, data: data });

        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async saveDataCompany() {
      try {
        let uploadImg;
        let urlimg = this.company.picture;
        if (this.filename1 != null) {
          console.log("entró en el if");
          this.filename1 = this.company_id + "_" + this.filename1;
          uploadImg = await this.$store.dispatch("subirarchivosCustom", { filename: this.filename1, file: this.file1, type: "company" });
          console.log("uploadimg ", uploadImg);
          if (uploadImg == true) {
            console.log("entró en el if 2 es true");
            urlimg = "https://admin.siscoper.net/img/company/" + this.filename1;
          }
        }
        console.log("save");
        let data = Object.assign({}, this.company);
        data.picture = urlimg;

        //data.empresa_id = this.company_id;
        let res;
        delete data.id;
        res = await this.$store.dispatch("put", { path: "empresas/modify/" + this.company_id, data: data });

        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async saveDataUser() {
      try {
        let data = {
          color: this.myEventColor,
        };
        let res = await this.$store.dispatch("put", { path: "usuarios/modify/" + this.userId, data: data });

        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
  },
};
</script>
<style scoped>
.inputbox {
  height: 60px;
  text-align: center;
}
.blue {
  color: blue;
  font-weight: bold;
}
.red {
  color: red;
  font-weight: bold;
}
.black {
  color: black;
  font-weight: bold;
}
</style>
