<template>
  <div :class="className" class="p-0 pointer mt-1" id="popover-target-1">
    <!-- <div class="" style="" >
       <span class="bg-btn-information " style="border-top-left-radius: 10px; border-bottom-right-radius: 10px; position: absolute; z-index: 999 !important; padding-top: 2px; padding-bottom: 2px; padding-left: 5px; padding-right: 5px;"><i class="fa-solid fa-circle-info"></i></span>
    </div> -->
    <div class="card custom-card mb-0">

      <div class="card-body">
        <h5 class="card-title text-left p-0"> {{ product.nombre.toUpperCase().substring(0, 22) }} </h5>
      </div>

      
      <div class="card-footer border-0 mx-0 px-2" style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; background-color: transparent !important; ">
        <p class="card-text text-left text-primar pl-1 price">C$ {{ product.precio_venta.toFixed(2) }}</p>
        <div class="mt-2">
          <button class="btn btn-primary btn-block" style="border-radius: 8px;" > 
            <i class="fa-solid fa-ellipsis"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="movil px-0">
      <div class="row">
        <div class="col-12">
          <div class="">
            <p class="trn text-center bg-rgb-primary py-2 mb-0">
              <b>{{ product.nombre.toUpperCase().substring(0, 22) }}</b>
            </p>
            <div class="">
              <div class="text-center">
                <span v-if="product.precio_venta" class="mt-2 h3 text-primary bg-dark"
                  ><b>${{ product.precio_venta.toFixed(2) }}</b></span
                ><br />
                <span v-if="product.existencia" class="mt-2">
                  <!-- <b>Stock: {{ product.existencia }}</b> -->
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-6">
          <img class="productimg" :src="product.url == null || product.url == 'null' ? '../images/img2.png' : product.url" alt="" />
        </div> -->
      </div>
    </div>
<!-- 
<popoverProductSalesV2> </popoverProductSalesV2>    popover------------------------- -->

  </div>
</template>
<script>
// import popoverProductSalesV2 from "@/components/sicoComponent/popoverProductSalesV2.vue";

export default {
  // components: { popoverProductSalesV2 },
  props: ["product", "theme", "type"],
  data: () => ({
    className: "product",
    user: null,
  }),
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
    if (this.theme) {
      this.className = this.theme;
    }
  },
  methods: {
    goto(rutaWeb) {
      console.log("goto ruta: ", rutaWeb);
      this.$router.push(rutaWeb);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: rgba(255, 0, 119, 1);
.hover-dark {
}
.hover-dark:hover {
  background-color: rgb(173, 0, 0);
}
.trn {
  position: relative;
  line-height: 18px;
  // height: 36px;
  overflow: hidden;
}
// .trn::after {
//   font-weight: bold;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   // padding: 0 20px 1px 45px;
// }
.shadow_card {
  background: transparent;
  box-shadow: 0 0.185rem 0.3rem rgba(0, 0, 0, 0.452) !important;
}

h4 {
  color: $primary;
}

.product-alt {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  // color: #fff;
  border-color: blue;
}
.productimg {
  height: 5rem;
  width: 100%;
  display: block;
  //   margin-left:auto;
  //  margin-right: auto;
  object-fit: cover;
  // border-radius: 15px; padding:10px
}

@media screen and (max-width: 750px) {
  .productimg {
    height: 10rem;

    display: block;
    //   margin-left:auto;
    //  margin-right: auto;
    object-fit: cover;
    // border-radius: 15px; padding:10px
  }
  .card-div-prod {
    height: auto !important;
  }
}
.card-div-prod {
  // height: 11vw !important;
}

.card_prod {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  text-align: center;
  margin: 1px !important;
  width: 11vw;
}
.card-size {
  height: 70px;
}
.card_prod:hover {
  background-color: rgb(248, 248, 248) !important;
}

.custom-card {
  border: none !important;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.26);
  flex: 1 0 300px;
  margin-right: 5px; /* Espacio entre las tarjetas */
  height: 120px;
  max-height: 130px;
  position: relative;
}
.custom-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
  object-fit: cover;
}
.custom-card .card-body {
  padding: 0.8rem;
  font-weight: bold !important;
}
[data-theme="dark" ]{
  .custom-card .card-title {
  font-size: 2vh;
  margin-bottom: 0.5rem;
  color: #f0f0f0;
}
}
.custom-card .card-title {
  font-size: 2vh;
  margin-bottom: 0.5rem;
  color: #333333;
}
.custom-card .card-text {
  // color: #666;
  margin-bottom: 0px !important;
}
.custom-card .price {
  font-size: 2.3vh;
  font-weight: bold;
  // color: #b8b507;
}
.custom-card .score {
  font-size: 0.7rem;
  color: #28a745;
}
</style>
