<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card pb-4 px-3 mb-4">
        <div class="row pb-3 justify-content-between">
          <div  class="img-bg text-light  sales d-flex flex-wrap justify-content-between align-items-center ">
            <div class="">
              <p class="h3 pb-2">Ventas o ingresos</p>
              <input @click="$router.push('/purchasesSales/salesDetail')" type="button" class="btn btn-primary" v-b-modal.exampleModalXl value="Nueva venta" />
              <input @click="$router.push('/purchasesSales/newFreeSale')" type="button" class="btn btn-light mx-3" v-b-modal.exampleModalXl value="Nueva venta libre" />
            </div>
            <card-actions></card-actions>
          </div>

          <div class="col-md-5">
            <b-form-group label="Por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(fecha_i)="data">
            <span>{{ data.item.fecha_i != null ? $moment(data.item.fecha_i).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(nombre)="data">
            <span>{{ data.item.nombre != null ? data.item.nombre : "Cliente genérico" }}</span>
          </template>
          <template #cell(total_venta)="data">
            <span>{{ config.moneda + data.item.total_venta.toFixed(2) }}</span>
          </template>
          <template #cell(total_costos)="data">
            <span>{{ config.moneda + data.item.total_costos.toFixed(2) }}</span>
          </template>
          <template #cell(margen)="data">
            <span>{{ config.moneda + data.item.margen.toFixed(2) }}</span>
          </template>
          <template #cell(action)="data">
            <span
              ><button @click="showInvoice(data.item)" class="btn btn-primary ml-1"><i class="fa fa-eye"></i></button>
            </span>
            <!-- <span data-toggle="tooltip" data-placement="top" title="Estado de cuenta"
              ><button @click="gotoAccountStatus(data.item)" class="btn btn-orange ml-1"><i class="fa fa-pencil"></i></button>
            </span> -->
            <span
              ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1"><i class="fa fa-trash-o"></i></button>
            </span>

            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
            <!-- <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

            <!-- <table-action></table-action> -->
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            hide-goto-end-buttons
            hide-ellipsis
            prev-text="Prev"
            next-text="Next"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal id="exampleModalCenter" tabindex="-1" centered :title="modalTitle" hide-footer size="xl">
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-2 mb-3">
              <label for="email" class="">User id</label
              ><input placeholder="0" required class="form-control input-size-lg" v-model="row.email" type="text" name="email" id="email" />
              <!-- <label for="nombre" class="">Nombre</label><input required v-model="row.nombre" class="form-control input-size-lg" type="text" name="nombre" id="nombre" > -->
            </div>
            <div class="col-md-1 mb-3 mt-4">
              <b-button variant="danger" class="mb-2"> <b-icon class="fa fa-plus px-3" aria-hidden="true"></b-icon></b-button>
            </div>
            <div class="col-md-3 mb-3">
              <label for="email" class="">Nombre del cliente</label><input required class="form-control input-size-lg" v-model="row.email" type="text" name="email" id="email" />
            </div>
            <div class="col-md-3 mb-3">
              <label for="nombre" class="">Selecciona sucursal</label><b-form-select class="form-select input-size-lg" v-model="selected" :options="options"></b-form-select>

              <!-- <label for="nombre" class="">Nombre</label><input required v-model="row.nombre" class="form-control input-size-lg" type="text" name="nombre" id="nombre" > -->
            </div>
            <div class="col-md-3 mb-3">
              <label for="nombre" class="">Estado</label><b-form-select class="form-select input-size-lg" v-model="selected" :options="options"></b-form-select>

              <!-- <label for="nombre" class="">Nombre</label><input required v-model="row.nombre" class="form-control input-size-lg" type="text" name="nombre" id="nombre" > -->
            </div>
            <div class="col-md-1 mb-3 mt-4">
              <b-button variant="primary" class="mb-2"> <b-icon class="fa fa-plus px-3" aria-hidden="true"></b-icon></b-button>
            </div>
            <div class="col-md-5 mb-3">
              <label for="nombre" class="">Ingresa Nombre del Producto</label
              ><b-form-select class="form-select input-size-lg" v-model="selected" :options="options"></b-form-select>
              <!-- <label for="nombre" class="">Nombre</label><input required v-model="row.nombre" class="form-control input-size-lg" type="text" name="nombre" id="nombre" > -->
            </div>
            <div class="col-md-2 mb-3">
              <label for="email" class="">Cantidad</label
              ><input required class="form-control input-size-lg" v-model="row.email" type="text" name="email" id="email" placeholder="0" />
            </div>
            <div class="col-md-2 mb-3">
              <label for="email" class="">Pre unit. {{ config.moneda }}</label
              ><input required class="form-control input-size-lg" v-model="row.email" type="text" name="email" id="email" placeholder="0" />
            </div>
            <div class="col-md-2 mb-3">
              <label for="email" class="">Total {{ config.moneda }}</label
              ><input required class="form-control input-size-lg" v-model="row.email" type="text" name="email" id="email" placeholder="0" />
            </div>
            <div class="col-md-12 mb-3">
              <label for="email" class="">Descripción</label><input required class="form-control input-size-lg" v-model="row.email" type="text" name="email" id="email" />
            </div>

            <!-- <div class="col-md-1 mb-3 mt-2"><label for="observaciones" class="">Descripción</label></div>
                        <div class="col-md-11"><input class="form-control input-size-lg" v-model="row.observaciones" type="text" name="observaciones" id="observaciones"></div> -->
          </div>
          <div class="card p-4 mb-4">
            <div class="row justify-content-between">
              <div class="card-header d-flex flex-wrap justify-content-between align-items-center">
                <h4><b> Venta - Detalle</b></h4>
              </div>
            </div>
            <b-table
              hover
              responsive
              :items="items"
              :fields="fields2"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
            >
              <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
              <template #cell(fecha_alta)="data">
                <span>{{ data.item.fecha_alta != null ? $moment(data.item.fecha_alta).format("DD-MM-YYYY") : "" }}</span>
              </template>

              <template #row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                  </ul>
                </b-card>
              </template>
            </b-table>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                hide-goto-end-buttons
                hide-ellipsis
                prev-text="Prev"
                next-text="Next"
                align="right"
              ></b-pagination>
            </div>
          </div>
          <div class="px-3">
            <div class="mb-2 row border">
              <div class="col-md-2 mb-3 mt-3">
                <label for="email" class="">Total Pagado $</label
                ><input required class="form-control input-size-lg" v-model="row.email" type="text" name="email" id="email" placeholder="0" />
              </div>
              <div class="col-md-2 offset-md-1 mb-3 mt-3">
                <label for="email" class="">Total Pagado $</label
                ><input required class="form-control input-size-lg" v-model="row.email" type="text" name="email" id="email" placeholder="0" />
              </div>
              <div class="col-md-2 offset-md-2 mb-3 mt-3">
                <b-button class="mt-3 fa fa-plus success p-2" variant=" mr-1"><span class="ml-1 btn-lg-text"> PROCESAR</span></b-button>
              </div>
              <div class="col-md-2 offset-md-1 mb-3 mt-3">
                <b-button class="mt-3 fa fa-minus p-2" variant="danger mr-1"><span class="ml-1 btn-lg-text"> CANCELAR</span></b-button>
              </div>
            </div>
          </div>

          <div class="text-right">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CardActions from "@/components/core/CardActions.vue";
import { EventBus } from "@/js/event-bus.js";

export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
    "card-actions": CardActions,
  },
  data() {
    return {
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        { key: "nombre", label: "Cliente", sortable: true, class: "text-left" },
        { key: "fecha_i", label: "Fecha ", sortable: true, class: "text-left" },
        // { key: 'sucursal', label: 'Sucursal', sortable: true, class: 'text-center' },
        { key: "total_venta", label: "Total Venta", sortable: true, class: "text-right" },
        { key: "total_costos", label: "Total costos", sortable: true, class: "text-right" },
        { key: "iva", label: "Iva", sortable: true, class: "text-right" },
        { key: "margen", label: "Margen", sortable: true, class: "text-right" },
        { key: "estado", label: "Estado", sortable: true, class: "text-right" },
        { key: "action", label: "Acciones", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      fields2: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        { key: "articulo", label: "Articulo", sortable: true, class: "text-center" },
        { key: "cantidad", label: "Cantidad ", sortable: true, class: "text-center" },
        { key: "precio_unit", label: "Precio unit", sortable: true, class: "text-center" },
        { key: "sub_total", label: "Sub total ", sortable: true, class: "text-center" },

        { key: "action", label: "acción", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      ///////
      table: "meca_ventas",
      items: [],
      row: {},
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo Cliente",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      selected: "",
      options: null,
      config: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    EventBus.$emit("isToogle", false);

    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");
    this.config = JSON.parse(localStorage.getItem("config"));
    this.getData();
  },
  methods: {
    gotoAccountStatus(item) {
      console.log("item-->", item);
      const routerData = this.$router.resolve({ path: "/accounting/accountStatus/nn/" + item.id });
      window.open(routerData.href, "_blank");
    },
    showInvoice(item) {
      this.$router.push("/purchasesSales/invoicePreview/" + item.id);
    },
    showModal(item, id) {
      console.log("----->", item);
      if (id == 1) {
        this.modalTitle = "Nuevo Cliente";
        this.row = {};
        this.$bvModal.show("exampleModalCenter");
      } else {
        this.row = item;
        this.modalTitle = "Editar Cliente";
        this.$bvModal.show("exampleModalCenter");
        console.log(this.row);
      }
    },
    async getData() {
      this.$isLoading(true);

      try {
        let res = await this.$store.dispatch("get", { path: this.table + "/getAllByCompany/" + this.company_id });

        if (res.length > 0) {
          this.items = res;
          this.totalRows = this.items.length;
        } else {
          this.items = [];
          this.totalRows = 0;
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        this.items = [];
        this.totalRows = 0;
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        console.log("save");
        let data = Object.assign({}, this.row);
        data.empresa_id = this.company_id;
        let res;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", { path: this.table + "/modify/" + this.row.id, data: data });
        } else {
          res = await this.$store.dispatch("post", { path: this.table + "/new", data: data });
        }
        if (res.error) {
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.getData();
          this.row = {};
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          let res = await this.$store.dispatch("delete", { path: this.table + "/delete/" + item.id });
          if (res.error) {
            this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          } else {
            this.getData();
            this.$swal({ title: "Aviso", text: "Eliminado correctamente", icon: "success" });
          }
        } catch (error) {
          this.items = [];
          console.error("error--->", error);
        }
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped></style>
